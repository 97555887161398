import React, {useCallback, useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import {useSearchParam} from 'react-use'
import styled from 'styled-components'
import {useAssistantTemplates} from 'lib/obie/use-assistant-templates'
import {useCreateAssistant} from 'lib/obie/use-create-assistant'
import Button from 'lib/ui/Button'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import TextField from 'lib/ui/TextField'
import Logo from 'organization/Obie/Logo'
import BlockCreateDependencies, {
  getDependenciesOrSubmit,
  getRequiredAssistantCategories,
  useDependencySelector,
} from 'organization/Obie/Blocks/BlockCreateDependencies'
import {useAllBlocks} from 'organization/Obie/ObieServiceProvider'
import {useOrganization} from 'organization/OrganizationProvider'

export default function CreateAssistantForm() {
  const {data: allBlocks} = useAllBlocks()
  const {data: assistantTemplates} = useAssistantTemplates()
  const {handleSubmit, control} = useForm()
  const {routes} = useOrganization()
  const {setOpenDependencySelector} = useDependencySelector()
  const assistantTemplateId = useSearchParam('assistant_template_id')
  const createAssistant = useCreateAssistant()
  const history = useHistory()

  const [name, setName] = useState<string>('')

  const assistantTemplate = assistantTemplates?.find(
    (template) => template.id === parseInt(assistantTemplateId ?? ''),
  )

  useEffect(() => {
    if (!assistantTemplate) {
      return
    }

    setName(assistantTemplate.default_name)
  }, [assistantTemplate])

  const handleCreateSubmit = useCallback(
    (dependencies?: Record<string, any>, suppliedName?: string) => {
      if (!assistantTemplate) {
        return
      }

      return createAssistant.mutate({
        assistantTemplateId: assistantTemplate.id,
        name: suppliedName || name,
        instructions: assistantTemplate.base_instructions,
        dependencies,
      })
    },
    [assistantTemplate, createAssistant, name],
  )

  if (!assistantTemplate) {
    history.push(routes.obie.assistants.root)
    return null
  }

  const onSubmit = (formData: any) => {
    if (createAssistant.isLoading) {
      return
    }

    // This shouldn't happen, the "name" input is marked as "required".
    if (!formData.name) {
      return
    }

    setName(formData.name)

    const dependencyCategories = getRequiredAssistantCategories(
      assistantTemplate.dependencies,
      allBlocks,
    )

    const dependencyResult = getDependenciesOrSubmit({
      hasDependencies: Boolean(assistantTemplate?.dependencies),
      dependencyCategories,
    })

    // When dependencyResult is null, there are no dependencies to process so we
    // can createAssistant() straight away.
    if (dependencyResult === null) {
      return handleCreateSubmit()
    }

    // When dependencyResult is an object (Record<string, any>...), there are
    // dependencies, but they were able to be resolved without user having to
    // interact with the selector. In this case we can createAssistant, WITH
    // the resolved dependencies.
    if (typeof dependencyResult === 'object') {
      return handleCreateSubmit(dependencyResult, formData.name)
    }

    // There are dependencies to resolve, but we couldn't figure them out
    // without user saying something, so we have to show the selector.
    setOpenDependencySelector(true)
  }

  return (
    <PageContainer>
      <BlockCreateDependencies
        allBlocks={allBlocks}
        onSubmit={handleCreateSubmit}
        assistantDependencies={assistantTemplate.dependencies}
      />

      <BackLink to={routes.obie.assistants.root}>Back to Assistants</BackLink>
      <Header>
        <StyledLogo /> Assistants
      </Header>
      <div>
        <p>Create a new {assistantTemplate.default_name}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            defaultValue={assistantTemplate.default_name}
            render={({value, onChange}) => (
              <StyledTextField
                fullWidth
                name="name"
                onChange={onChange}
                required
                type="text"
                value={value || ''}
                variant="outlined"
              />
            )}
          />
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={createAssistant.isLoading}
          >
            Create
          </StyledButton>
        </form>
      </div>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
`

const Header = styled.h1`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 36px;
  line-height: 64px;
  color: #ffffff;
  gap: 1rem;
`

const StyledLogo = styled(Logo)`
  padding: 0;
`

const StyledTextField = styled(TextField)`
  color: #ffffff;

  input {
    background: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const BackLink = styled(RelativeLink)`
  color: #fff;
`
