import React from 'react'
import {useLocation, Link, useHistory} from 'react-router-dom'
import styled from 'styled-components'
import DeleteIcon from '@material-ui/icons/Close'
import Skeleton from '@material-ui/lab/Skeleton'
import {useRouteAssistant} from 'lib/obie/use-assistant'
import {useCreateAssistantThread} from 'lib/obie/use-create-assistant-thread'
import {useDeleteAssistantThread} from 'lib/obie/use-delete-assistant-thread'
import Button from 'lib/ui/Button'
import {GearIcon} from 'lib/ui/Icon'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {buildRoute} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'

export default function AssistantsSidebar() {
  const {data: assistant} = useRouteAssistant()
  const createThread = useCreateAssistantThread()
  const {routes} = useOrganization()
  const location = useLocation()
  const history = useHistory()

  const deleteThread = useDeleteAssistantThread()
  const processing = createThread.isLoading || deleteThread.isLoading

  const handleCreateThread = () => {
    if (processing) {
      return
    }

    if (!assistant) {
      return
    }

    createThread.mutate(assistant, {
      onSuccess: (thread) => {
        history.push(
          buildRoute(
            routes.obie.assistants[':assistant'].threads[':thread'].root,
            assistant.id,
            thread.id,
          ),
        )
      },
    })
  }

  const handleDeleteThread = (thread: {id: number; assistant_id: number}) => {
    if (processing) {
      return
    }
    if (!assistant) {
      return
    }

    const isLastThread = assistant.threads.length === 1

    deleteThread.mutate(thread, {
      onSuccess: () => {
        // If the last thread was deleted, create a new one
        if (isLastThread) {
          createThread.mutate(assistant, {
            onSuccess: (thread) => {
              history.push(
                buildRoute(
                  routes.obie.assistants[':assistant'].threads[':thread'].root,
                  assistant.id,
                  thread.id,
                ),
              )
            },
          })

          return
        }

        const currentThreadIndex = assistant.threads.findIndex(
          (t) => t.id === thread.id,
        )

        const nextThread =
          assistant.threads[currentThreadIndex + 1] || assistant.threads[0]
        if (nextThread) {
          history.push(
            buildRoute(
              routes.obie.assistants[':assistant'].threads[':thread'].root,
              assistant.id,
              nextThread.id,
            ),
          )
        }
      },
    })
  }

  return (
    <Sidebar>
      <ChatsContainer>
        {assistant ? (
          <NameContainer>
            <SettingsLink
              to={buildRoute(
                routes.obie.assistants[':assistant'].settings,
                assistant?.id ?? '',
              )}
            >
              <GearIcon title="Settings" />
            </SettingsLink>
            <Heading>{assistant.name}</Heading>
          </NameContainer>
        ) : (
          <Skeleton
            variant="text"
            height={24}
            style={{
              width: '100%',
              background: '#1a2847',
              marginBottom: '12px',
            }}
          />
        )}
        <StyledButton
          color="primary"
          variant="contained"
          fullWidth
          disabled={!assistant || createThread.isLoading}
          onClick={handleCreateThread}
        >
          New Chat
        </StyledButton>
        <ChatList>
          {assistant ? (
            <>
              {assistant.threads.map((thread) => (
                <Link
                  to={buildRoute(
                    routes.obie.assistants[':assistant'].threads[':thread']
                      .root,
                    assistant.id,
                    thread.id,
                  )}
                  style={{flex: 1}}
                  key={thread.id}
                >
                  <ChatItem
                    isActive={location.pathname.includes(
                      `/threads/${thread.id}`,
                    )}
                  >
                    <ChatTitle>{thread.title ?? 'New Chat'}</ChatTitle>
                    <DeleteButton
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleDeleteThread(thread)
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </DeleteButton>
                  </ChatItem>
                </Link>
              ))}
            </>
          ) : (
            <>
              <ChatLoader />
              <ChatLoader />
              <ChatLoader />
            </>
          )}
        </ChatList>
      </ChatsContainer>
      <BackToObieLink to={routes.obie.assistants.root}>
        Back to Assistants
      </BackToObieLink>
      <BackToObieLink to={routes.obie.root}>Back to Obie</BackToObieLink>
    </Sidebar>
  )
}

function ChatLoader() {
  return (
    <Skeleton
      variant="text"
      height={40}
      style={{
        width: '100%',
        background: '#1a2847',
      }}
    />
  )
}

const Sidebar = styled.div`
  width: 282px;
  background: #050f27;
  border-right: 1px solid #1e273d;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledButton = styled(Button)``

const ChatsContainer = styled.div`
  height: 100%;
`

const ChatList = styled.div`
  margin-top: ${(props) => props.theme.spacing[8]};
`

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s, color 0.2s;

  &:hover {
    opacity: 1;
    color: ${(props) => props.theme.colors.error};
  }
`

const ChatItem = styled.div<{isActive?: boolean}>`
  align-items: center;
  background: ${(props) =>
    props.isActive ? 'rgb(26, 40, 71)' : 'rgba(26, 40, 71, 0.3)'};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 12px;
  transition: transform 0.2s;

  &:hover {
    background: #1a2847;
    transform: ${(props) => (props.isActive ? 'none' : 'translateY(-2px)')};

    ${DeleteButton} {
      opacity: 1;
    }
  }
`

const ChatTitle = styled.div`
  color: #fff;
  font-size: 14px;
`

const Heading = styled.h1`
  color: #fff;
  font-size: 16px;
  margin: 0;
`

const NameContainer = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.spacing[2]};
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const SettingsLink = styled(RelativeLink)`
  color: #fff;
  display: block;
  font-size: 16px;
  height: 100%;
  margin-right: ${(props) => props.theme.spacing[3]};
`

const BackToObieLink = styled(RelativeLink)`
  color: #fff;
  font-size: 14px;
  padding-top: ${(props) => props.theme.spacing[4]};
`
