import {obieApi} from 'lib/obie/url'
import {useOrganizationObieClient} from 'lib/obie/use-organization-obie-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'
import {AssistantThread} from 'lib/obie/use-assistant'

export function useDeleteAssistantThread() {
  const {client} = useOrganizationObieClient()
  const qc = useQueryClient()
  const {organization} = useOrganization()

  return useMutation({
    mutationFn: (data: {id: number; assistant_id: number}) => {
      const {id} = data

      return client!.delete(
        obieApi(`/organizations/${organization.id}/assistant_threads/${id}`),
      )
    },
    onSuccess: (_, data) => {
      qc.setQueryData<AssistantThread[]>(
        ['organizations', organization.id, 'assistants_threads'],
        (threads) => {
          if (!threads) {
            return []
          }

          return threads.filter((thread) => thread.id !== data.id)
        },
      )

      qc.invalidateQueries([
        'organizations',
        organization.id,
        'assistants',
        data.assistant_id,
      ])
    },
  })
}
