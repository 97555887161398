import React, {ReactElement, useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import styled from 'styled-components'
import MuiTextField from '@material-ui/core/TextField'
import {browserTimezone} from 'lib/date-time'
import {usePrevious} from 'lib/state'
import {api, useQueryParams} from 'lib/url'
import FormQuestion from 'organization/Obie/Blocks/ProcessForm/Form/FormQuestion'
import {
  Answer,
  AnswerSet,
  Block,
  Question,
  useAnswerSetCreate,
  useObieService,
} from 'organization/Obie/ObieServiceProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {
  getDependenciesOrSubmit,
  RequiredCategory,
  useDependencySelector,
} from 'organization/Obie/Blocks/BlockCreateDependencies'

export const MULTIPLE_CHOICE_SEPARATOR = '~|~'

export const QUESTION_TYPE_LONG_FORM = 1
export const QUESTION_TYPE_RADIO_OPTIONS = 2
export const QUESTION_TYPE_PATH_PARENT = 3
export const QUESTION_TYPE_PATH_LONG_FORM = 4
export const QUESTION_TYPE_PATH_RADIO_OPTIONS = 6
export const QUESTION_TYPE_PATH_MULTIPLE_CHOICE = 7
export const QUESTION_TYPE_MULTIPLE_CHOICE = 5

export const ANIMATION_DELAY_MS = 350
export const ANIMATION_SLIDE_IN_FROM_RIGHT = 'slideInFromRight'
export const ANIMATION_SLIDE_OUT_TO_LEFT = 'slideOutToLeft'
export const ANIMATION_SLIDE_IN_FROM_LEFT = 'slideInFromLeft'
export const ANIMATION_SLIDE_OUT_TO_RIGHT = 'slideOutToRight'

export interface ObieQuestionsProps {
  animatingQuestions: number[]
  animationIn: string
  animationOut: string
  answerSetId: number
  clickUnsavedCancel: () => void
  clickUnsavedDiscard: () => void
  currentAnswer: string
  currentQuestionId: number
  currentQuestionNumber: number
  dependencyBlockId: number
  dependencyCategories: RequiredCategory[]
  goToNext: (
    profileId: number | undefined,
    shortCircuitForSave?: boolean,
  ) => void
  goToPrevious: () => void
  hasAnswers: boolean
  hasDependencies: boolean
  initializeBlock: (block: Block) => void
  isSubmitting: boolean
  openUnsavedDialog: boolean
  percentage: number
  renderPathQuestion: (
    profileId: number | undefined,
    question: Question,
  ) => ReactElement | null
  renderQuestion: (
    profileId: number | undefined,
    question: Question,
  ) => ReactElement | null
  setCurrentAnswer: (answer?: string | string[] | undefined) => void
  setCurrentSelectedOptionId: (optionId: number | undefined) => void
  setCurrentSelectedPathId: (pathId: number | undefined) => void
  setDependencyCategories: (categories: RequiredCategory[]) => void
  setHasDependencies: (has: boolean) => void
  setQuestions: (questions: Question[]) => void
  submissionError: string
  submitAnswer: (
    blockId: number,
    questionId: number,
    answerSetId: number,
    answerValue: string | string[],
    answer?: Answer,
  ) => void
  submitCompletion: (
    blockId?: number,
    providedAnswerSetId?: number | void,
    dependencies?: Record<string, any>,
  ) => void
  submitWithDependencies: (
    hasDependencies: boolean,
    blockId?: number,
    answerSetId?: number,
  ) => void
}

export type FormQuestionProps = {
  profileId: number
  answerSetId: number
  question: Question
  answer?: Answer
  setDirty?: () => void
}

export interface AnswerResponse {
  answer: Answer
  answers: Answer[]
  results: {
    created: number
    'no-op': number
    updated: number
  }
}

export const ObieQuestions = React.createContext<
  ObieQuestionsProps | undefined
>(undefined)

export default function ObieQuestionsProvider(props: {
  children: React.ReactElement
}) {
  const [animatingQuestions, setAnimatingQuestions] = useState<number[]>([])
  const [animationIn, setAnimationIn] = useState<string>(
    ANIMATION_SLIDE_IN_FROM_RIGHT,
  )
  const [animationOut, setAnimationOut] = useState<string>(
    ANIMATION_SLIDE_OUT_TO_LEFT,
  )
  const [answers, setAnswers] = useState<Answer[]>([])
  const [answerSetId, setAnswerSetId] = useState(0)
  const [answerSets, setAnswerSets] = useState<AnswerSet[]>([])
  const [currentAnswer, setCurrentAnswerValue] = useState<string>('')
  const [currentQuestionId, setCurrentQuestionId] = useState<number>(0)
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(1)
  const [currentSelectedOptionId, setCurrentSelectedOptionId] = useState<
    number | undefined
  >()
  const [currentSelectedPathId, setCurrentSelectedPathId] = useState<
    number | undefined
  >()
  const [hasAnswers, setHasAnswers] = useState<boolean>(false)
  const [hasDependencies, setHasDependencies] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false)
  const [percentage, setPercentage] = useState<number>(0)
  const [questionDirty, setQuestionDirty] = useState<boolean>(false)
  const [questions, setQuestions] = useState<Question[]>([])
  const [redirectPath, setRedirectPath] = useState<string | null>(null)
  const [submissionError, setSubmissionError] = useState<string>('')
  const [dependencyBlockId, setDependencyBlockId] = useState<number>(0)
  const [dependencyCategories, setDependencyCategories] = useState<
    RequiredCategory[]
  >([])

  const {answer_set_id, sessionId} = useQueryParams()
  const {
    categoryId,
    createCompletion,
    fetchBlocks,
    profileId,
    setAwaitingCompletion,
    setCompletion,
  } = useObieService()
  const {setOpenDependencySelector} = useDependencySelector()

  const history = useHistory()
  const {routes} = useOrganization()

  // When the array of Questions is set, we want to set the current pointer to
  // the first Question in the stack.
  useEffect(() => {
    if (!questions.length) {
      return
    }

    setCurrentQuestionId(questions[0].id)
  }, [questions, setCurrentQuestionId])

  // Any time the answers array changes, we want to set a flag if there are any
  // actual Answers provided. This is a "dirty" flag for whoever cares.
  useEffect(() => {
    if (answers.length) {
      setHasAnswers(true)
      return
    }

    setHasAnswers(false)
  }, [answers, setHasAnswers])

  // When the answer_set_id is available as a query string param, it means a user
  // is editing Answers to an existing Answer Set.
  useEffect(() => {
    // No answer_set_id, means there's nothing in the query string and we don't
    // have anything to do in this scenario.
    if (!answer_set_id) {
      return
    }

    // Attempt to find the Answer Set that matches the provided answer_set_id.
    const answerSet = answerSets.find(
      (answerSet) => answerSet.id === Number(answer_set_id),
    )

    // No Answers for the provided answer_set_id (this shouldn't be a real world
    // scenario, but in case), get out of here.
    if (!answerSet) {
      return
    }

    // We have some values to set so that user sees the first Question with the
    // already provided Answer for it.
    setAnswers(answerSet.answers)
    setAnswerSetId(answerSet.id)
    setCurrentAnswer(answerSet.answers[0].answer)
  }, [answer_set_id, answerSets])

  /**
   * Finds a Question in the array of Questions, given an id.
   *
   * @param questionId number
   * @returns Question | undefined
   */
  const findQuestion = useCallback(
    (questionId: number): Question | undefined => {
      return questions.find((q) => q.id === questionId)
    },
    [questions],
  )

  /**
   * Finds an Answer for a particular Question in the array of Answers, given a
   * questionId.
   *
   * @param questionId number
   * @returns Answer | undefined
   */
  const findAnswer = useCallback(
    (questionId: number): Answer | undefined => {
      return answers.find((a) => a.question_id === questionId)
    },
    [answers],
  )

  // We need to refer to the previous value for the "current Question ID" after
  // it has changed, for travelling forward and backward through questions. At
  // the time that values are being populated, the currentQuestionId valud has
  // already changed and we need the ID from before it changes for decisions.
  const previousQuestionId = usePrevious(currentQuestionId)

  /**
   * Calculates the current "location" percentage through the Questions that need
   * to be answered for the Block.
   *
   * @return void
   */
  // const calculatePercentage = (): void => {
  const calculatePercentage = useCallback(() => {
    // Start by setting the thing we absolutely know, how many Questions there
    // are in the array of Questions.
    let numQuestions = questions.length
    let currentPosition = 0

    // Iterate the array of Questions to see if any of the Question types are a
    // PathParent.
    questions.forEach((question) => {
      // When we find a PathParent, we'll need to step through the options on that
      // parent to see if we can add more questions to the count beacuse of any
      // paths that may exist.
      if (question.type === QUESTION_TYPE_PATH_PARENT) {
        // If there are no options available on this PathParent question, there
        // is nothing to compare.
        if (!question.options.length) {
          return
        }

        // We need the answer to this PathParent question, so when we iterate the
        // available options, we can see which option (path) was chosen.
        const answer = findAnswer(question.id)

        // No Answer for this Question yet? No knowledge to add to the number of
        // questions.
        if (!answer) {
          return
        }

        // Iterate the options on this PathParent until we find the option that
        // was chosen by user, so we can add the number of questions in that path
        // to the count of questions - to figure out percentages.
        question.options.forEach((option) => {
          if (option.value === answer.answer) {
            numQuestions += option.path.length
          }
        })
      }
    })

    // We need to take into account which direction through the Answers is being
    // travelled. If we're going forward (currentQuestionId is greater than the
    // previousQuestionId), we want to examine through the Answers up until the
    // LAST Answer provided. If the other direction (we're moving backwards), we
    // need to examine up to the CURRENT Answer, because we've already decremented
    // the position at this point of execution.
    const checkQuestionId =
      currentQuestionId > previousQuestionId
        ? previousQuestionId
        : currentQuestionId

    // Iterate the Answer array until we find the currentQuestionId's value. We
    // do this because the array of Answers is in the correct order (from the
    // backend). We're looking through this array because the user may have clicked
    // back through some Answers to change things. This percentage is a "location",
    // not an actual "progress".
    answers.forEach((answer, idx) => {
      if (answer.question.id === checkQuestionId) {
        currentPosition = idx + 1
      }
    })

    // For the same direction-aware logic as above (figuring out checckQuestionId),
    // we need a one-offset position to figure out the "percentage" depending on
    // which way we're travelling through the Answers.
    const mathPosition =
      currentQuestionId > previousQuestionId
        ? currentPosition
        : currentPosition - 1

    // Percent-math.
    setPercentage((mathPosition / numQuestions) * 100)
  }, [
    setPercentage,
    answers,
    currentQuestionId,
    findAnswer,
    previousQuestionId,
    questions,
  ])

  // Whenever the currentQuestionId changes, we want to calculate the percentage.
  // This "percentage" is tricky, because it's more of a "current location",
  // opposed to the "percentage completed". But because it's being rendered in a
  // ProgressBar component, we need a percentage value.
  useEffect(() => {
    calculatePercentage()
  }, [currentQuestionId, calculatePercentage])

  /**
   * At the beginning of the OBIE form process, we need to set the Questions and
   * any availble Answer Sets into state, the process examines both while a user
   * is answring Questions
   *
   * @param block Block
   * @return void
   */
  const initializeBlock = (block: Block) => {
    setQuestions(block.questions)
    setAnswerSets(block.answer_sets || [])
    setCompletion()
  }

  /**
   * Handler for when user clicks on the cancel (X to close) button on the "You
   * Have Unsaved Changes" dialog during the form process.
   */
  const clickUnsavedCancel = () => {
    setOpenUnsavedDialog(false)
    setRedirectPath(null)
  }

  /**
   * Handler for when user clicks on the discard button on the "You Have Unsaved
   * Changes" dialog during the form process.
   */
  const clickUnsavedDiscard = () => {
    setOpenUnsavedDialog(false)
    if (redirectPath) {
      history.block(() => true)
      history.push(redirectPath)
    }
  }

  /**
   * Dead simple handler for the Question components to call when a value changes
   * to mark the current Question as dirty, so we'll know when to show the "You
   * Have Unsaved Changes" dialog.
   */
  const setDirty = () => {
    setQuestionDirty(true)
  }

  // Sets up the listeners on the window to determine when user is trying to
  // navigate away from the form process. We need these events so we can determine
  // if we should render the "You Have Unsaved Changes" dialog for a decision.
  usePreventNavigation(
    questionDirty,
    openUnsavedDialog,
    setOpenUnsavedDialog,
    setRedirectPath,
  )

  /**
   * Manipulates an incoming value before setting it to state. Incoming Answer
   * values could vary, depending on its source, this is where it is normalized.
   *
   * @param value string | string[] | undefined
   * @returns void
   */
  const setCurrentAnswer = (value?: string | string[] | undefined) => {
    // If the value coming in is not present, set the state to an empty string
    // and leave.
    if (!value) {
      setCurrentAnswerValue('')

      return
    }

    // If the value coming in a string, set the state to this value, no special
    // handling needed here.
    if (typeof value === 'string') {
      setCurrentAnswerValue(value)

      return
    }

    // Only thing left is string[], so join at MULTIPLE_CHOICE_SEPARATOR because
    // that's how we're dealing with multiple selections for this Question type.
    setCurrentAnswerValue(value.join(MULTIPLE_CHOICE_SEPARATOR))
  }

  /**
   * Renders a FormQuestion for a provided Question.
   *
   * @param question Question
   * @returns ReactElement
   */
  const renderQuestion = (
    profileId: number | undefined,
    question: Question,
  ): ReactElement | null => {
    if (profileId === undefined) {
      return null
    }

    return (
      <FormQuestion
        key={question.id}
        profileId={profileId}
        answerSetId={0}
        question={question}
        setDirty={setDirty}
      />
    )
  }

  /**
   * Decides whether to render a FormQuestion if the selected option matches the
   * path_id of the incoming Question. Meant for PathQuestion type Questions that
   * should only show subsequent Questions within their own path.
   *
   * @param question Question
   * @param selectedOptionId number | undefined
   * @returns ReactElement | null
   */
  const renderPathQuestion = (
    profileId: number | undefined,
    question: Question,
  ): ReactElement | null => {
    if (profileId === undefined) {
      return null
    }

    if (question.path_id !== currentSelectedPathId) {
      return null
    }

    return renderQuestion(profileId, question)
  }

  // Hook to submit Answers
  const submitAnswerRequest = useSubmitAnswer()
  /**
   * Submits a single Answer to the backend to be stored in an AnswerSet.
   *
   * @param blockId number
   * @param questionId number
   * @param answerSetId number
   * @param answerValue string | string[]
   * @param answer Answer
   * @returns Promise<AnswerResponse>
   */
  const submitAnswer = (
    blockId: number,
    questionId: number,
    answerSetId: number,
    answerValue: string | string[],
    answer?: Answer,
  ): Promise<number | void> => {
    setSubmissionError('')
    setIsSubmitting(true)

    return submitAnswerRequest(
      blockId,
      questionId,
      answerSetId,
      answerValue,
      answer,
    )
      .then((res) => {
        setIsSubmitting(false)
        setAnswerSetId(res.answer.answer_set_id)
        setAnswers(res.answers)

        return res.answer.answer_set_id
      })
      .catch((err) => {
        setIsSubmitting(false)
        setSubmissionError(err.message)
      })
  }

  // Hook to create an AnswerSet
  const answerSetCreateRequest = useAnswerSetCreate()

  /**
   * Creates a Completion in the backend when an AnswerSet is fully provided by
   * user. Creates an AnswerSet in the case where an incoming Block ID does not
   * have any Questions to answer... There still needs to be an AnswerSet to
   * create the Completion against.
   *
   * @param blockId number
   * @returns Promise<Completion>
   */
  const submitCompletion = (
    blockId?: number,
    providedAnswerSetId?: number | void,
    dependencies?: Record<string, any>,
  ) => {
    setAwaitingCompletion(true)
    setCurrentQuestionId(0)

    // When we don't have an answerSetId in state and we DO have a blockId, we
    // need to create the AnswerSet in the backend first, so that we have all
    // the parts needed for a Completion to be submitted.
    if (!(providedAnswerSetId || answerSetId) && blockId) {
      // Two-request process in this scenario.
      return answerSetCreateRequest(categoryId, profileId, blockId).then(
        (res) => {
          setAnswerSetId(res.id)

          return createCompletion(res.id, dependencies, Number(sessionId)).then(
            (res) => {
              // Set the Completion response into state so we can initiate the
              // "typewriter" UX.
              setCompletion(res)
              setAwaitingCompletion(false)
            },
          )
        },
      )
    }

    return createCompletion(
      providedAnswerSetId || answerSetId,
      dependencies,
      Number(sessionId),
    ).then((res) => {
      // Set the Completion response into state so we can initiate the
      // "typewriter" UX.
      setCompletion(res)
      setAwaitingCompletion(false)
    })
  }

  /**
   * Travels to the next Question in the array of Questions for the form process.
   * Conditions are evaluated to determine which Question is next, based on
   * Question types.
   *
   * @param shortCircuitForSave boolean | undefined
   * @returns void
   */
  const goToNext = (
    profileId: number | undefined,
    shortCircuitForSave?: boolean,
  ): void => {
    if (profileId === undefined) {
      return
    }

    setAnimationIn('')
    setAnimationOut('')

    // Look through the array of Questions to find if the currentQuestionId is a
    // "main" question - not a path Question.
    const mainQuestion = questions.find((q) => q.id === currentQuestionId)

    // Look through any Questions in the array that are PathParent Questions, so
    // we can iterate through the path options to figure out if the currentQuestionId
    // is within a path.
    let pathQuestion
    questions.forEach((question) => {
      if (question.type !== QUESTION_TYPE_PATH_PARENT) {
        return
      }

      question.options.forEach((option) => {
        if (!option.path.length) {
          return
        }

        option.path.forEach((p) => {
          if (p.id === currentQuestionId) {
            pathQuestion = p
          }
        })
      })
    })

    // Set the resolvedQuestion to the mainQuestion value if one was found (meaning
    // a "main" Question is currently being answered) and secondly the pathQuestion
    // value that was iterated for.
    const resolvedQuestion = mainQuestion || pathQuestion

    // If we couldn't fina a mainQuestion or a pathQuestion, we've got a problem.
    if (!resolvedQuestion) {
      console.error('Could not find Question (next)')
      return
    }

    // If the Question is required, but there isn't an answer provided, we have
    // an error and can't move on.
    if (resolvedQuestion.required && !currentAnswer) {
      setSubmissionError('This answer is required')
      return
    }

    // Determine the next Question's ID that we can render - whether it's
    // a parent or path Question.
    const nextQuestionId = getNextQuestionId(resolvedQuestion)
    const existingAnswer = findAnswer(currentQuestionId)

    const resolvedAnswer =
      resolvedQuestion.type === QUESTION_TYPE_MULTIPLE_CHOICE ||
      resolvedQuestion.type === QUESTION_TYPE_PATH_MULTIPLE_CHOICE
        ? currentAnswer.split(MULTIPLE_CHOICE_SEPARATOR)
        : currentAnswer

    // Submit the Answer to the backend so we can record the progress of this
    // AnswerSet.
    submitAnswer(
      resolvedQuestion.block_id,
      resolvedQuestion.id,
      answerSetId,
      resolvedAnswer,
      existingAnswer,
    ).then(() => {
      // If the shortCircuitForSave flag is true, we know this method is being
      // called from the "You Have Unsaved Changes" dialog and user clicked the
      // "Save" button. There is a bunch of resolution (above) that is done to
      // save an Answer, so we're utilizing the GoToNext functionality before
      // saving it and ONLY saving it, then going "back".
      if (shortCircuitForSave) {
        const resolvedRedirectPath = redirectPath || routes.obie.root

        resetState()
        fetchBlocks(categoryId, profileId)

        history.block(() => true)
        history.push(resolvedRedirectPath)

        return
      }

      // When the nextQuestionId value is greater than 0, we've found a next
      // Question that user can move to.
      if (nextQuestionId > 0) {
        setCurrentAnswer()

        // Indicate the current Question and the next Question are going to be
        // animated for some UX.
        setAnimatingQuestions([currentQuestionId, nextQuestionId])

        setQuestionDirty(false)
        setCurrentQuestionId(nextQuestionId)

        // We may already have an Answer for the next Question, so let's look
        // for it and set it as "current".
        const existingAnswer = findAnswer(nextQuestionId)

        // A little delay so the next Question information is not populated
        // before the Question is actually rendered in the view.
        setTimeout(() => {
          setCurrentAnswer(existingAnswer?.answer)
          setCurrentQuestionNumber((current) => current + 1)
        }, ANIMATION_DELAY_MS)

        // Both Questions sliding from-right-to-left.
        setAnimationIn(ANIMATION_SLIDE_IN_FROM_RIGHT)
        setAnimationOut(ANIMATION_SLIDE_OUT_TO_LEFT)

        return
      }

      // When the nextQuestionId value is 0, we've exhausted all Questions to
      // answer in this form process, so we have to submit a Completion.
      if (nextQuestionId === 0) {
        setQuestionDirty(false)

        submitWithDependencies(
          hasDependencies,
          resolvedQuestion.block_id,
          answerSetId,
        )
      }
    })
  }

  const submitWithDependencies = (
    hasDependencies: boolean,
    blockId?: number,
    answerSetId?: number,
  ) => {
    if (!dependencyCategories) {
      console.error('No dependencyCategories found')
      return
    }

    const dependencyResult = getDependenciesOrSubmit({
      hasDependencies,
      dependencyCategories,
    })

    // When dependencyResult is null, there are no dependencies to process
    // so we can submitCompletion() straight away.
    if (dependencyResult === null) {
      return submitCompletion(blockId, answerSetId)
    }

    // When dependencyResult is an object (Record<string, any>...), there are
    // dependencies, but they were able to be resolved without user having to
    // interact with the selector. In this case we can submitCompletion, WITH
    // the resolved dependencies.
    if (typeof dependencyResult === 'object') {
      return submitCompletion(blockId, answerSetId, dependencyResult)
    }

    // There are dependencies to resolve, but we couldn't figure them out
    // without user saying something, so we have to show the selector.
    if (blockId) {
      setDependencyBlockId(blockId)
    }

    setOpenDependencySelector(true)
  }

  /**
   * Works through some complex nuance to determine which Question in the array
   * of Questions is the next one. PathParent types are taken into account, when
   * appropriate options are selected.
   *
   * @param currentQuestion Question
   * @returns number
   */
  const getNextQuestionId = (currentQuestion: Question): number => {
    // First we're checking if the currentQuestion is a PathParent and if it is,
    // we have to examine the selectedOption's path to find the next Question to
    // render.
    if (currentQuestion.type === QUESTION_TYPE_PATH_PARENT) {
      // Find the selectedOption so we know which path to start examining.
      const selectedOption = currentQuestion.options.find(
        (option) => option.id === currentSelectedOptionId,
      )

      // This can't be a scenario that happens, but TypeScript is happier.
      if (!selectedOption) {
        return -1
      }

      // If there is a Question in this path, we've got our ID to send back.
      if (selectedOption.path[0]) {
        return selectedOption.path[0].id
      }

      // If we made it to this point, we know that we're supposed to start a path,
      // but there doesn't seem to be any Questions in this path. Outside here,
      // we'll check for next parent Questions.
    }

    // If we have a PathQuestion*, this means we're already in a path and need to
    // find the next Question in that path.
    if (
      currentQuestion.type === QUESTION_TYPE_PATH_LONG_FORM ||
      currentQuestion.type === QUESTION_TYPE_PATH_RADIO_OPTIONS ||
      currentQuestion.type === QUESTION_TYPE_PATH_MULTIPLE_CHOICE
    ) {
      // Since we're in a path already, we need to know the parent Question, so
      // that we can look further down the path we're in.
      const parentQuestion = findQuestion(currentQuestion.parent_question_id)

      // This can't be a scenario that happens, but TypeScript is happier.
      if (!parentQuestion) {
        return -2
      }

      // Find the currently selected option from the parent's path.
      const selectedOption = parentQuestion.options.find(
        (option) => option.id === currentSelectedPathId,
      )

      // This can't be a scenario that happens, but TypeScript is happier.
      if (!selectedOption) {
        return -3
      }

      // Find the current Question from the path.
      const pathQuestion = selectedOption.path.find(
        (question) => question.id === currentQuestionId,
      )

      // This can't be a scenario that happens, but TypeScript is happier.
      if (!pathQuestion) {
        return -4
      }

      // We need the index of this path Question so we can attempt to identify
      // the NEXT Question in this path.
      const indexOf = selectedOption.path.indexOf(pathQuestion)

      // If we do have another Question in this path, we have an ID to send back.
      if (selectedOption.path[indexOf + 1]) {
        return selectedOption.path[indexOf + 1].id
      }

      // If we made it to this point, there are no more Questions in the path, so
      // we're done with this branch of conditions. Outside here, we'll check for
      // next parent Questions.
    }

    // Down here means that the currentQuestion is NOT the beginning of a path or
    // within a path. We need to figure out where in the stack this Question is
    // to determine if there are more Questions or if we need to signal a Completion.
    //
    // If the currentQuestion is a PathQuestion, we need to find the parent's
    // position to determine if there are more Questions. If it's NOT a PathQuestion,
    // we can use the currentQuestion's ID to determine position.
    const sourceQuestionId =
      currentQuestion.type === QUESTION_TYPE_PATH_LONG_FORM ||
      currentQuestion.type === QUESTION_TYPE_PATH_RADIO_OPTIONS ||
      currentQuestion.type === QUESTION_TYPE_PATH_MULTIPLE_CHOICE
        ? currentQuestion.parent_question_id
        : currentQuestion.id

    const sourceQuestion = findQuestion(sourceQuestionId)
    const indexOf = questions.indexOf(sourceQuestion as Question)

    // Here we're checking for the next parent Question.
    if (questions[indexOf + 1]) {
      return questions[indexOf + 1].id
    }

    // Haven't resolved anyting, there are no more Questions in the path OR in
    // the array of Questions - we signal for Completion at this point.
    return 0
  }

  /**
   *
   * @returns void
   */
  const goToPrevious = (): void => {
    setQuestionDirty(false)
    setAnimationIn('')
    setAnimationOut('')

    // We're using the currentQuestionNumber so we don't have to do any complex
    // condition logic to determine where we are with PathQueston types, etc. We
    // have an Answer in the array of Answers for each Question that was answered,
    // regardless if it was a parent or a path Question. From this supplied
    // Answer, we have a Question ID that we can just render and fill with the
    // supplied Answer, nothign to check, no paths to resolve, fughetabouuutit.
    //
    // Subtract 2, beacuse currentQuestionNumber is human-based. #4 is actually
    // the 3 index in the array, but if we're on the 4th question (which is 3)
    // ready to answer it but haven't yet, user has only answered 3 questions...
    // so sub-2.
    const answerIndex = Number(currentQuestionNumber) - 2

    // If the answerIndex is below 0, means we're already on the first Question
    // and we don't want to go any further back.
    if (answerIndex < 0) {
      return
    }

    const lastAnswer = answers[answerIndex]

    setAnimatingQuestions([currentQuestionId, answers[answerIndex].question.id])
    setAnimationIn(ANIMATION_SLIDE_IN_FROM_LEFT)
    setAnimationOut(ANIMATION_SLIDE_OUT_TO_RIGHT)
    setCurrentQuestionId(lastAnswer.question.id)

    setTimeout(() => {
      setCurrentAnswer(lastAnswer.answer)
      setCurrentQuestionNumber((current) => current - 1)
    }, ANIMATION_DELAY_MS)
  }

  /**
   * Cleans up state values, all in one place.
   *
   * @return void
   */
  const resetState = (): void => {
    setCurrentQuestionId(0)
    setCurrentSelectedOptionId(undefined)
    setAnswers([])
    setAnswerSetId(0)
    setCurrentAnswer()
    setIsSubmitting(false)
    setSubmissionError('')
    setAnimationIn(ANIMATION_SLIDE_IN_FROM_RIGHT)
    setAnimationOut(ANIMATION_SLIDE_OUT_TO_LEFT)
    setAnimatingQuestions([])
    setPercentage(0)
    setOpenUnsavedDialog(false)
    setQuestionDirty(false)
  }

  return (
    <ObieQuestions.Provider
      value={{
        animatingQuestions,
        animationIn,
        animationOut,
        answerSetId,
        clickUnsavedCancel,
        clickUnsavedDiscard,
        currentAnswer,
        currentQuestionId,
        currentQuestionNumber,
        dependencyBlockId,
        dependencyCategories,
        goToNext,
        goToPrevious,
        hasAnswers,
        hasDependencies,
        initializeBlock,
        isSubmitting,
        openUnsavedDialog,
        percentage,
        renderPathQuestion,
        renderQuestion,
        setCurrentAnswer,
        setCurrentSelectedOptionId,
        setCurrentSelectedPathId,
        setDependencyCategories,
        setHasDependencies,
        setQuestions,
        submissionError,
        submitAnswer,
        submitCompletion,
        submitWithDependencies,
      }}
    >
      {props.children}
    </ObieQuestions.Provider>
  )
}

export function useObieQuestions() {
  const context = React.useContext(ObieQuestions)
  if (context === undefined) {
    throw new Error(
      `useObieQuestions must be used within a ObieQuestionsProvider`,
    )
  }

  return context
}

export function useSubmitAnswer() {
  const {client, organization} = useOrganization()
  const {categoryId, profileId} = useObieService()

  return useCallback(
    (
      blockId: number,
      questionId: number,
      answerSetId: number,
      value: string | string[],
      answer?: Answer,
    ): Promise<AnswerResponse> => {
      const baseUrl = api(
        `/organizations/${organization.id}/obie/categories/${categoryId}/blocks/${blockId}/answers`,
      )

      const url = answer ? `${baseUrl}/${answer.id}` : baseUrl

      const data = {
        profile_id: profileId,
        answer_set_id: answerSetId,
        answer: {
          question_id: questionId,
          answer: value,
        },
        browserTimezone: browserTimezone(),
      }

      return answer
        ? client.put<AnswerResponse>(url, data)
        : client.post<AnswerResponse>(url, data)
    },
    [categoryId, profileId, client, organization],
  )
}

function usePreventNavigation(
  isDirty: boolean,
  openDialog: boolean,
  setOpenDialog: (flag: boolean) => void,
  setRedirectPath: (newLocation: string) => void,
) {
  const history = useHistory()

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault()
      }
    }

    const handleBlockNavigation = (blocker: any) => {
      if (!isDirty) {
        return true
      }

      if (!openDialog) {
        setOpenDialog(true)
        setRedirectPath(blocker.pathname)
      }
      return false
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    const unblock = history.block(handleBlockNavigation)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      unblock()
    }
  }, [isDirty, history, openDialog, setOpenDialog, setRedirectPath])
}

export const StyledTextField = styled(MuiTextField)`
  & .MuiFilledInput-input {
    color: white;
  }
`
