import {useMutation, useQueryClient} from 'react-query'
import {obieApi} from 'lib/obie/url'
import {Assistant} from 'lib/obie/use-assistant'
import {useOrganizationObieClient} from 'lib/obie/use-organization-obie-client'
import {useOrganization} from 'organization/OrganizationProvider'

export function useDeleteAssistant() {
  const {client} = useOrganizationObieClient()
  const qc = useQueryClient()

  const {organization} = useOrganization()

  return useMutation({
    mutationFn: (data: {id: number}) => {
      const {id} = data
      return client!.delete(
        obieApi(`/organizations/${organization.id}/assistants/${id}`),
      )
    },
    onSuccess: (_data, {id}) => {
      qc.setQueryData<Assistant[]>(
        ['organizations', organization.id, 'assistants'],
        (assistants) => {
          if (!assistants) {
            return []
          }

          return assistants.filter((assistant) => assistant.id !== id)
        },
      )
    },
  })
}
