import React, {useEffect, useRef} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import styled from 'styled-components'
import ConfirmUnsavedChangesDialog from 'organization/Event/Configurable/ConfirmUnsavedChangesDialog'
import Complete from 'organization/Obie/Blocks/ProcessForm/Complete'
import ProgressBar from 'organization/Obie/Blocks/ProcessForm/ProgressBar'
import {useObieQuestions} from 'organization/Obie/ObieQuestionsProvider'
import {
  Block,
  useObieService,
  useAllBlocks,
} from 'organization/Obie/ObieServiceProvider'
import Page from 'organization/Obie/Blocks/ProcessForm/Page'
import Header from 'organization/Obie/Blocks/ProcessForm/Header'
import BlockCreateDependencies from 'organization/Obie/Blocks/BlockCreateDependencies'
import {profileRoute} from 'organization/Obie/ObieRoutes'
import {useOrganization} from 'organization/OrganizationProvider'

export default function ProcessForm() {
  const {
    org_id: _,
    category: category_id,
    block: block_id,
    profile: profile_id,
  } = useParams<{
    org_id: string
    category: string
    block: string
    profile: string
  }>()

  const {
    answerSetId,
    clickUnsavedDiscard,
    clickUnsavedCancel,
    dependencyBlockId,
    goToNext,
    initializeBlock,
    openUnsavedDialog,
    submitCompletion,
    submitWithDependencies,
  } = useObieQuestions()
  const {
    blocks,
    categoryId,
    completion,
    fetchBlocks,
    findBlockById,
    getBlock,
    loadingBlocks,
    profileId,
    setCategoryId,
    setProfileId,
  } = useObieService()

  const blockRef = useRef<Block>()

  const {data: allBlocks} = useAllBlocks()
  const {routes} = useOrganization()
  const history = useHistory()

  // Not to be confused between profile_id and profileId. The former is coming
  // out of the query string. The latter is coming out of state.
  useEffect(() => {
    if (!category_id) {
      return
    }

    // This is the beginning of the Profile-aware bits, so let's set it into state
    // so we don't have to pass it around through props.
    setCategoryId(parseInt(category_id))
  }, [setCategoryId, category_id])

  useEffect(() => {
    if (!profile_id) {
      return
    }

    // This is the beginning of the Profile-aware bits, so let's set it into state
    // so we don't have to pass it around through props.
    setProfileId(parseInt(profile_id))
  }, [setProfileId, profile_id])

  useEffect(() => {
    // We need the category_id and block_id and profile_id to be able to do this
    // work and we don't want to ALREADY be loadingBlocks in the provider.
    if (!blocks.length || !categoryId || !block_id || profileId === undefined) {
      return
    }

    if (!blockRef.current) {
      // We need the Block, based on query params from the listing page.
      blockRef.current = getBlock(categoryId, parseInt(block_id))

      const controlDependencies = Boolean(
        (blockRef.current.prompt.dependencies.control || []).length,
      )
      const standardDependencies = Boolean(
        (blockRef.current.prompt.dependencies.standard || []).length,
      )

      // If there are no Questions in the Block that user has started, all we can
      // do is submitCompletion(), there's no form that needs to be rendered here,
      // so we don't need to set the Questions into state.
      if (!blockRef.current.questions.length) {
        return submitWithDependencies(
          Boolean(controlDependencies || standardDependencies),
          blockRef.current.id,
          answerSetId,
        )
      }

      // Now that we have a Block out of the provider, we want to set the Questions
      // back into state, so we have everything we need to work from.
      initializeBlock(blockRef.current)
    }
  }, [
    answerSetId,
    block_id,
    blocks,
    categoryId,
    completion,
    getBlock,
    initializeBlock,
    profileId,
    submitWithDependencies,
  ])

  // If there are no blocks present and were not currently fetching them, do the
  // fetch...
  useEffect(() => {
    if (blocks.length || loadingBlocks) {
      return
    }

    fetchBlocks(categoryId, profileId)
  }, [blocks, categoryId, fetchBlocks, loadingBlocks, profileId])

  // ...and return null so we don't render anything yet.
  if (!blocks.length) {
    return null
  }

  const handleUnsavedChangesSubmit = () => {
    goToNext(profileId, true)
  }

  const handleDependencySubmit = (data: any) => {
    if (profileId === undefined) {
      return
    }

    submitCompletion(dependencyBlockId, answerSetId, data)
  }

  const handleDependencyClose = () => {
    const block = findBlockById(dependencyBlockId)

    if (!block) {
      console.error(`Could not find Block: ${dependencyBlockId}`)
      return
    }

    const pRoute = profileRoute(
      routes,
      String(block.category.id),
      String(profileId),
    )

    history.push(pRoute.root)
  }

  return (
    <Page>
      <ConfirmUnsavedChangesDialog
        expandable={false}
        onDiscard={clickUnsavedDiscard}
        onCancel={clickUnsavedCancel}
        open={openUnsavedDialog}
        onSubmit={handleUnsavedChangesSubmit}
      />
      <BlockCreateDependencies
        allBlocks={allBlocks}
        onSubmit={handleDependencySubmit}
        onClose={handleDependencyClose}
      />

      <ProgressBar hidden={Boolean(completion)} />

      <Header />
      <Complete category_id={category_id} completion={completion} />
      <FormContent block={blockRef.current} />
    </Page>
  )
}

function FormContent(props: {block?: Block}) {
  const {block} = props
  const {completion, profileId} = useObieService()
  const {renderQuestion} = useObieQuestions()

  // If there is a completion available, we don't want to render anything...
  if (completion || !block) {
    return null
  }

  return (
    <Content>
      {block.questions.map((question) => renderQuestion(profileId, question))}
    </Content>
  )
}

const Content = styled.div`
  position: relative;
  display: block;
  padding-left: ${(props) => props.theme.spacing[30]};
  padding-right: ${(props) => props.theme.spacing[30]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-left: ${(props) => props.theme.spacing[6]};
    padding-right: ${(props) => props.theme.spacing[6]};
  }
`
