import {client} from 'lib/ui/api-client'
import {useQuery} from 'react-query'
import {obieApi} from './url'

export interface AssistantTemplate {
  id: number
  default_name: string
  base_instructions: string
  dependencies: number[]
}

export function useAssistantTemplates() {
  return useQuery({
    queryKey: ['assistant_templates'],
    queryFn: () =>
      client.get<AssistantTemplate[]>(obieApi(`/assistant_templates`)),
  })
}
