import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useOrganizationObieClient} from 'lib/obie/use-organization-obie-client'
import {routesWithValue} from 'lib/url'
import Obie from 'organization/Obie'
import AssistantsRoutes from 'organization/Obie/Assistants/AssistantsRoutes'
import Blocks from 'organization/Obie/Blocks'
import {DependencySelectorProvider} from 'organization/Obie/Blocks/BlockCreateDependencies'
import ProcessForm from 'organization/Obie/Blocks/ProcessForm'
import ObieLayout from 'organization/Obie/Layout'
import ObieLockedPage from 'organization/Obie/ObieLockedPage'
import ObieQuestionsProvider from 'organization/Obie/ObieQuestionsProvider'
import PagRoutes from 'organization/Obie/PagRoutes'
import {useCanAccessObie} from 'organization/Obie/use-can-access-obie'
import {
  useOrganization,
  OrganizationRoutes,
} from 'organization/OrganizationProvider'

export function categoryRoute(routes: OrganizationRoutes, categoryId: string) {
  return routesWithValue(
    ':category',
    categoryId,
    routes.obie.categories[':category'],
  )
}

export function profileRoute(
  routes: OrganizationRoutes,
  categoryId: string,
  profileId: string,
) {
  return routesWithValue(
    ':profile',
    profileId,
    routesWithValue(
      ':category',
      categoryId,
      routes.obie.categories[':category'].profile[':profile'],
    ),
  )
}

export default function ObieRoutes() {
  const {routes} = useOrganization()

  const canAccessObie = useCanAccessObie()
  const {client} = useOrganizationObieClient()

  if (!canAccessObie) {
    return <ObieLockedPage />
  }

  // Wait for Obie client / Org token to be fetched
  if (!client) {
    return null
  }

  return (
    <Switch>
      <Route exact path={routes.obie.root}>
        <ObieLayout hideSidebar={false}>
          <Obie />
        </ObieLayout>
      </Route>
      <Route
        exact
        path={routes.obie.categories[':category'].profile[':profile'].root}
      >
        <ObieLayout hideSidebar={false}>
          <Blocks />
        </ObieLayout>
      </Route>
      <Route
        exact
        path={
          routes.obie.categories[':category'].profile[':profile'].blocks[
            ':block'
          ].process
        }
      >
        <ObieLayout hideSidebar={true}>
          <DependencySelectorProvider>
            <ObieQuestionsProvider>
              <ProcessForm />
            </ObieQuestionsProvider>
          </DependencySelectorProvider>
        </ObieLayout>
      </Route>
      <Route path={routes.obie.pag.root}>
        <PagRoutes />
      </Route>

      <Route path={routes.obie.assistants.root}>
        <DependencySelectorProvider>
          <ObieQuestionsProvider>
            <AssistantsRoutes />
          </ObieQuestionsProvider>
        </DependencySelectorProvider>
      </Route>
      <Redirect to={routes.obie.root} />
    </Switch>
  )
}
