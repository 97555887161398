import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {Controller, UseFormMethods, useForm} from 'react-hook-form'
import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {onUnknownChangeHandler} from 'lib/dom'
import Button from 'lib/ui/Button'
import Dialog from 'lib/ui/Dialog'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {useObieQuestions} from 'organization/Obie/ObieQuestionsProvider'
import {
  Block,
  Category,
  Profile,
  useObieService,
} from 'organization/Obie/ObieServiceProvider'

export interface RequiredCategory extends Category {
  blocks: Block[]
}

export default function BlockCreateDependencies(props: {
  allBlocks: Block[] | null
  onSubmit?: (data: any) => void
  onClose?: () => void
  assistantDependencies?: number[]
}) {
  const {allBlocks, assistantDependencies} = props

  const {handleSubmit, control} = useForm()
  const {findBlockById} = useObieService()
  const {
    dependencyBlockId,
    dependencyCategories,
    setDependencyCategories,
  } = useObieQuestions()
  const {
    openDependencySelector,
    setOpenDependencySelector,
  } = useDependencySelector()

  /**
   * Initializes the dependency selection state when a block is selected.
   *
   * This effect runs when:
   * - A dependency block ID is provided
   * - The block finder function changes
   * - The available blocks list changes
   * - The dependency categories setter changes
   *
   * The effect:
   * 1. Finds the selected block using the provided ID
   * 2. Retrieves all required categories for the block's dependencies
   * 3. Updates the local block state
   * 4. Stores the dependency categories for later automatic resolution
   *
   * @dependency dependencyBlockId - ID of the block to process dependencies for
   * @dependency findBlockById - Function to locate a block by its ID
   * @dependency allBlocks - Complete list of available blocks
   * @dependency setDependencyCategories - State setter for dependency categories
   */
  useEffect(() => {
    const block = findBlockById(dependencyBlockId)
    const categories = assistantDependencies
      ? getRequiredAssistantCategories(assistantDependencies, allBlocks)
      : getRequiredBlockCategories(block, allBlocks)

    if (!categories || !categories.length) {
      return
    }

    // We need these categories later for automatic dependency resolution, so
    // we're going to store them in state to NOT have to retrieve them again.
    setDependencyCategories(categories)
  }, [
    allBlocks,
    assistantDependencies,
    dependencyBlockId,
    findBlockById,
    setDependencyCategories,
  ])

  /**
   * Handles closing the dependency selector modal by closing the modal and
   * triggering the parent's onClose callback.
   *
   * @callback
   * @dependency props.onClose - Parent callback to handle modal closure
   * @dependency setOpenDependencySelector - State setter for modal visibility
   */
  const onClose = useCallback(() => {
    setOpenDependencySelector(false)
    props.onClose && props.onClose()
  }, [props, setOpenDependencySelector])

  /**
   * Handles the form submission for block dependencies.
   * Closes the dependency selector modal and passes the selected dependencies
   * to the parent component.
   *
   * @param data - Form data containing the selected dependencies. Each key
   *   represents a block's prompt name and its value is the selected answer set
   *   ID.
   */
  const onSubmit = (data: any) => {
    setOpenDependencySelector(false)
    props.onSubmit && props.onSubmit(data)
  }

  /**
   * Early return guard clause that prevents rendering if required conditions
   * aren't met.
   *
   * Returns null if any of these conditions are true:
   * - The dependency selector modal is not open
   * - No block is currently selected
   * - No dependency categories exist
   * - The dependency categories array is empty
   *
   * @returns null if any condition fails, allowing component to continue
   *   rendering otherwise
   */
  if (
    !openDependencySelector ||
    !dependencyCategories ||
    !dependencyCategories.length
  ) {
    return null
  }

  return (
    <Dialog expandable={false} open onClose={onClose}>
      <DialogTitle>Completion dependencies are needed</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {dependencyCategories.map((category) => (
            <DependencyCategoryFields
              key={category.id}
              category={category}
              control={control}
            />
          ))}

          <StyledButton type="submit" variant="contained" color="primary">
            Continue
          </StyledButton>
          <Button
            type="button"
            variant="contained"
            color="grey"
            onClick={onClose}
          >
            Cancel
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

function DependencyCategoryFields(props: {
  category: RequiredCategory
  control: UseFormMethods['control']
}) {
  const {category, control} = props
  const [targetProfileId, setTargetProfileId] = useState<number | null>(null)
  const {categoryId: currentCategoryId, profileId} = useObieService()

  // If the category.id we're processing is the same as the Block being created's
  // category.id... we set this flag to indicate that we're inside the same
  // category, for comparison below.
  const isSameCategory = category.id === currentCategoryId

  useEffect(() => {
    if (targetProfileId) {
      return
    }

    // If we happen to be inside the same Category as the Block being created,
    // we set the targetProfileId value to whatever the current profile ID is,
    // since we're not going to show a profile selector in this case.
    if (isSameCategory) {
      setTargetProfileId(profileId || 0)
      // Short-circuit because we don't want to evaluate how many profiles there
      // are, we're using whatever the currently selected profile is.
      return
    }

    // If there are no profiles in the Category, set the targetProfileId to 0 to
    // indicate the "Default Profile" - this does not exist in the result set
    // from the backend. Also check if there is one Profile and if it's the
    // Default Profile.
    if (
      !category.profiles.length ||
      (category.profiles.length === 1 && category.profiles[0].id === 0)
    ) {
      setTargetProfileId(0)
    }
  }, [category, isSameCategory, profileId, targetProfileId])

  // We only want to show a ProfileSelector, if we NOT in the same category as
  // the Block being created. Within the same category has to stick to the same
  // Profile. We check for length of profiles because the Default Profile is in
  // this list. If the length is only 1, there aren't any Profiles to pick.
  const shouldSelectProfile = !isSameCategory && category.profiles.length > 1

  return (
    <>
      <h2>{category.name}</h2>
      {shouldSelectProfile && (
        <ProfileSelector
          profiles={category.profiles}
          onSelect={setTargetProfileId}
          value={targetProfileId}
        />
      )}
      {targetProfileId !== null && (
        <AnswerSetContainer>
          {category.blocks.map((block) => (
            <AnswerSetSelector
              key={`${block.id}-${targetProfileId}`}
              block={block}
              control={control}
              targetProfileId={targetProfileId}
            />
          ))}
        </AnswerSetContainer>
      )}
    </>
  )
}

function ProfileSelector(props: {
  profiles: Profile[]
  onSelect: (profileId: number) => void
  value: number | null
}) {
  const {profiles, onSelect, value} = props
  return (
    <Select
      onChange={onUnknownChangeHandler((value) => onSelect(Number(value)))}
      fullWidth
      label="Profile's Completions to use"
      value={value?.toString() ?? ''}
    >
      {profiles.map((profile) => (
        <Option key={profile.id} value={profile.id.toString()}>
          {profile.name}
        </Option>
      ))}
    </Select>
  )
}

function AnswerSetSelector(props: {
  block: Block
  control: UseFormMethods['control']
  targetProfileId: number
}) {
  const {block, control, targetProfileId} = props

  const answerSets = block.answer_sets.filter((answerSet) => {
    return answerSet.profile_id === targetProfileId && answerSet.complete
  })

  if (answerSets.length === 1) {
    return (
      <Controller
        name={block.prompt.name}
        control={control}
        defaultValue={answerSets[0].id}
        render={({onChange, value}) => (
          <input
            type="hidden"
            name={block.prompt.name}
            value={value}
            onChange={onChange}
          />
        )}
      />
    )
  }

  if (answerSets.length === 0) {
    return (
      <Controller
        name={block.prompt.name}
        control={control}
        defaultValue={''}
        render={({value, onChange}) => (
          <StyledSelect
            label={block.block}
            fullWidth
            required
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
            aria-label={`pick completed block ${block.id}`}
          >
            <Option value="">No Complete Blocks</Option>
          </StyledSelect>
        )}
      />
    )
  }

  return (
    <Controller
      name={block.prompt.name}
      control={control}
      defaultValue={''}
      render={({value, onChange}) => (
        <StyledSelect
          label={block.block}
          fullWidth
          required
          value={value}
          hidden={block.answer_sets.length === 1}
          onChange={onUnknownChangeHandler(onChange)}
          aria-label={`pick completed block ${block.id}`}
        >
          {answerSets.map((answerSet) => (
            <Option value={answerSet.id} key={answerSet.id}>
              {answerSet.name}
            </Option>
          ))}
        </StyledSelect>
      )}
    />
  )
}

export function getRequiredBlockCategories(
  target: Block | undefined,
  blocks: Block[] | null,
) {
  if (!target || !blocks) {
    return
  }
  const categories: Record<string, RequiredCategory> = {}

  // Iterate the listing of ALL Blocks to see which (if any) exist in our target's
  // dependencies. "Target" is the Block/Completion that is being created.
  for (const block of blocks) {
    let skipControl = false
    let skipStandard = false

    // Skip when THIS Block's ID is not in our target's dependencies.
    if (!(target.prompt.dependencies.standard || []).includes(block.id)) {
      skipStandard = true
    }

    if (
      !(target.prompt.dependencies.control || []).filter((dependency) =>
        new RegExp(`.*\\^${block.id}`).test(dependency),
      ).length
    ) {
      skipControl = true
    }

    if (skipControl && skipStandard) {
      continue
    }

    // When THIS Block's ID happens to be in the target's dependencies, we need
    // to include this Category, so we can process it properly.
    const existingCategory = categories[block.category.id]
    const existingBlocks = existingCategory?.blocks || []

    categories[block.category.id] = {
      ...block.category,
      blocks: [...existingBlocks, block],
    }
  }

  return Object.values(categories)
}

export function getRequiredAssistantCategories(
  assistantDependencies: number[] | undefined,
  blocks: Block[] | null,
) {
  if (!assistantDependencies || !blocks) {
    return
  }

  const categories: Record<string, RequiredCategory> = {}

  for (const blockId of assistantDependencies) {
    const block = blocks.find((b) => b.id === blockId)

    if (!block) {
      continue
    }

    // When THIS Block's ID happens to be in the target's dependencies, we need
    // to include this Category, so we can process it properly.
    const existingCategory = categories[block.category.id]
    const existingBlocks = existingCategory?.blocks || []

    categories[block.category.id] = {
      ...block.category,
      blocks: [...existingBlocks, block],
    }
  }

  return Object.values(categories)
}

export const getDependenciesOrSubmit = (params: {
  hasDependencies?: boolean
  dependencyCategories?: RequiredCategory[]
}): boolean | null | Record<string, any> => {
  const {dependencyCategories, hasDependencies} = params

  // If there are no dependencies, don't need to do anything here, just submit.
  // We return null so the calling component knows that there is nothing to do.
  if (!hasDependencies) {
    return null
  }

  // If we managed to get all the completed dependency data without needing any
  // user interaction, then we return the dependency data. e.g only one profile
  // per category, and only one answer set per block.
  const dependencyData = getCompletedDependencyData(dependencyCategories)

  // If we have a dependencyData object and it's not empty, means we were able
  // to get the dependencies without user interaction, we'll return the list so
  // the calling component can submit.
  if (dependencyData && Object.keys(dependencyData).length) {
    return dependencyData
  }

  // In this case we need to have user select their dependencies, so we have to
  // show the selector. The calling component should check for true to determine
  // the selector needs to be rendered.
  return true
}

/**
 * Attempts to get dependency data for all required categories. If any category
 * requires a profile selection, or a block requires an answer set selection,
 * then this function will return null.
 *
 * @param categories
 */
export function getCompletedDependencyData(
  categories?: RequiredCategory[],
): Record<string, any> | null {
  const data: Record<string, any> = {}

  if (!categories) {
    return data
  }

  for (const category of categories) {
    for (const block of category.blocks) {
      // If there is more than zero profiles, ask the user to pick one. More than
      // zero, because everyone has the Default Profile. If there happens to be
      // more than just the Default Profile, user needs to pick one.
      if (category.profiles.length > 1) {
        return null
      }

      const profile = category.profiles[0]
      const answerSets = block.answer_sets.filter(
        (answerSet) =>
          answerSet.profile_id === (profile?.id || 0) && answerSet.complete,
      )

      // Only able to auto-select if there is only one answer set.
      if (answerSets.length !== 1) {
        return null
      }

      data[block.prompt.name] = answerSets[0].id
    }
  }

  return data
}

export interface DependencySelectorProps {
  openDependencySelector: boolean
  setOpenDependencySelector: (state: boolean) => void
}

const DependencySelectorContext = createContext<
  DependencySelectorProps | undefined
>(undefined)

export function DependencySelectorProvider(props: {
  children: React.ReactElement
}) {
  const [openDependencySelector, setOpenDependencySelector] = useState<boolean>(
    false,
  )

  return (
    <DependencySelectorContext.Provider
      value={{
        openDependencySelector,
        setOpenDependencySelector,
      }}
    >
      {props.children}
    </DependencySelectorContext.Provider>
  )
}

export function useDependencySelector() {
  const context = useContext(DependencySelectorContext)
  if (!context) {
    throw new Error(
      'useDependencySelector must be used within DependencySelectorProvider',
    )
  }
  return context
}

const StyledSelect = styled(Select)<{
  hidden?: boolean
}>`
  display: ${(props) => (props.hidden === true ? 'none' : 'block')};
`

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[3]} 0;
  margin-right: ${(props) => props.theme.spacing[3]};
`

const AnswerSetContainer = styled.div`
  margin-left: ${(props) => props.theme.spacing[8]};
`
